@import '../../styles/base.scss';

.Template {
  padding: 1.375rem 2.25rem 1.625rem;
  &-text-area {
    @include font($fontFamily: $font-family-default !important, $size: 0.8125rem !important, $weight: 400);
    width: 100%;
    height: 8.375rem;
    padding: 1rem !important;
    border-radius: 0.25rem;
    border: 0.03125rem solid rgba($black, 0.2);
    background-color: $concrete !important;
    &::placeholder {
      font-style: italic;
      color: rgba($black, 0.6);
    }
  }
  &.custom.editing textarea {
    background-color: $white !important;
  }
}
