@import '../../styles/base.scss';

.Site-configuration {
  .Panel [class^='Panel-titleBar'] {
    padding-right: 0.5rem;
  }
  .Button {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .Table {
    thead {
      background-color: $grey97;
    }
    .deletion,
    .dragging {
      display: none;
    }
    &.isEditing {
      .deletion,
      .dragging {
        display: table-cell;
      }
    }
  }
}
