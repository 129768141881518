@import '../../styles/base.scss';

.Draft-Changes {
  &-content {
    width: 100%;

    &-table {
      th,
      td {
        padding-left: 2.5rem !important;
        padding-right: 4rem !important;
      }
      tr {
        .name {
          min-width: 250px;
        }
        .type {
          text-transform: capitalize;
          min-width: 200px;
        }
        .scheduled-publish-date,
        .updated-date {
          width: 16rem;
          p {
            margin: 0;
          }
        }
        .publishing {
          min-width: 145px;
          padding-right: 2rem !important;
        }
      }
    }
  }
}
