@import '../../styles/base.scss';

.ReactPlayer-container {
  &.standard {
    padding: 3.25rem 0;
    background: $grey30;
  }

  &.small {
    .ReactPlayer,
    .ReactPlayer-unavailable {
      height: 165px;
    }
  }
}

.ReactPlayer {
  aspect-ratio: 16 / 9;
  width: 100%;

  [class^='video-js'] {
    height: 100%;
    width: 100%;
  }

  .vjs-modal-dialog {
    &.vjs-text-track-settings {
      height: 100%;
    }
  }
}

.ReactPlayer-unavailable {
  display: flex;
  align-items: center;
  color: $white;
  height: 200px;
}
