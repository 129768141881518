@import '../../../styles/base.scss';
.LocaleInformationEpisodes {
  .AutoCompleteTags {
    [class^='TagListInput-container'],
    [class^='Tag-tag'] {
      border-radius: $border-radius;
      padding-right: 1rem;
      border-color: $grey20;
    }
    [class^='Tag-tag'] {
      background: $primary-color;
      color: $black;
      border: 1px solid $primary-color;
      div:nth-child(2) {
        color: $black;
      }
    }
    [class^='InputGroup-labelLeft'] label {
      font-family: $font-family-default;
      font-weight: normal;
      color: #999 !important;
    }
  }
  .name,
  .short-description,
  .keywords {
    width: 47.4rem;
    input,
    textarea {
      font-family: $font-family-default;
      font-size: 0.875rem;
      width: 100%;
    }
  }

  .cta-text {
    min-width: 140px;
  }
}
