@import '../../styles/base.scss';

.Service-messages-view {
  margin-left: 1rem;
  margin-top: 0;
  margin-right: 2.5rem;
  &-title {
    color: $dark-grey;
    line-height: 0;
  }
  &-content {
    color: $black;
  }
}

.Service-messages-create {
  margin-left: 1rem;
  margin-top: 0;
  margin-right: 2.5rem;
  width: 100%;

  &-title {
    color: $dark-grey;
    line-height: 0;
  }
  &-subtitle {
    color: $dark-grey;
    line-height: 0;
    font-size: 12px;
  }
  &-content {
    color: $black;
  }
  &-body {
    width: 100%;
    height: 134px;
    border-radius: $border-radius;
    border: 1px solid $grey20;
    padding: 1rem;
    font-family: $font-family-default;
  }
}

.Service-Message-Modal {
  width: 60% !important;
}
