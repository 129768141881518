.Activity-log-detail {
  .panel-content {
    padding-left: 0.9375rem;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 400px;
  }
  .activity-type {
    &-description {
      text-transform: capitalize;
    }
  }
  pre {
    font-family: inherit;
    line-height: 1.625rem;
  }
}
