@import '../../../styles/base.scss';

.Panel.interactivity-panel {
  padding: 0;
  border-radius: $border-radius;
  border: none;
  > div {
    border-radius: 0;
  }
  [class^='Panel-titleBar'] {
    margin: 0;
    border-radius: $border-radius $border-radius 0 0;
    h2 {
      margin-left: 0;
    }
  }
  thead {
    background-color: $grey97;
    .cta-title {
      padding-left: 1.25rem;
    }
  }
  tbody {
    .cta-title {
      padding-left: 1.5rem;
    }
    .cta-status {
      min-width: 15.625rem;
    }
  }
}

.Add-duration-modal {
  width: 30.25rem;
  margin: 0 auto;
  &-header {
    width: 100%;
    height: 3.625rem;
    background-color: $black;
    border-radius: $border-radius $border-radius 0 0;
    h2 {
      font-size: 1.25rem;
      color: $white;
      margin: 0;
    }
  }
  &-content {
    background-color: $white;
    padding: 1.5rem 0;
    .cta-duration-input {
      width: 6.25rem;
    }
  }
  &-actions {
    background: $white;
    padding: 1.5rem;
    border-radius: 0 0 $border-radius $border-radius;
  }
}
