@import '../../styles/base.scss';

.Footer {
  @include flexBox($display: flex, $alignItems: center, $justifyContent: center);
  position: relative;
  margin: 0 auto;
  text-align: center;
  background-color: $black;
  height: $header-height;
  p {
    @include font($fontFamily: $font-family-default, $color: rgba($white, 0.9), $size: 1rem, $lh: 1.1875rem);
    margin: 0;
  }
}
