@import '../../styles/base.scss';

.Events {
  .EventsContent {
    width: calc(100% - $sidebar-width);
  }

  .EventsTable {
    .name {
      min-width: 180px;
    }
    .date {
      width: 230px;
    }
    .published {
      padding-right: 1rem;
    }
    .status {
      min-width: 130px;
    }
  }
}
