@import '../../styles/base.scss';

.LocalizedLabels {
  &-column-update-item {
    &-title {
      color: $black;
      text-decoration: none;
    }
    &-description {
      color: $dark-grey;
    }
  }
  &-table {
    table {
      th,
      td {
        margin: 0;
        padding: 0.7rem 0;
      }

      th:first-child,
      td:first-child {
        padding-left: 2.5rem;
        width: 5rem;
      }
      tr th:nth-child(3),
      tr td:nth-child(3) {
        width: 50%;
      }
    }
  }
}

.LocalizedLabelsDetail {
  margin-left: 1rem;
  margin-top: 0;
  margin-right: 2.5rem;
  &-title {
    color: $dark-grey;
    line-height: 0;
  }
  &-subtitle {
    color: $dark-grey;
    line-height: 0;
    font-size: 12px;
  }
  &-content {
    color: $black;
  }
  &-body {
    width: 760px;
    height: 134px;
    border-radius: $border-radius;
    border: 1px solid $grey20;
    padding: 1rem;
    font-family: $font-family-default;
  }
}
