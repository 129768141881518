@import '../../styles/base.scss';

.Pagination {
  background: $white;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 0.5rem 0;
  &-button {
    height: 28px !important;
    padding: 0 0.8rem !important;
  }
  &-info {
    margin: 0 3.5rem;
  }
}
