@import '../../../styles/colors';
.Speakers {
  .image-container {
    width: 125px;
    height: 110px;
    background-color: $grey20;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
