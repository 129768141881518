@import '../../styles/base.scss';
.Generic-modal-create-modal {
  margin: 0 auto;
  & [class^='Modal-body'] {
    padding: 0;
    border: 0;
    background: none;
    width: 100%;
    overflow: visible;
  }
  & [class^='Modal-actions'] {
    display: none;
  }
}
[class^='Modal-content'] {
  max-width: unset !important;
}
