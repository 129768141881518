@import '../../styles/base.scss';
.SimpleSelect {
  font-family: inherit !important;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23808080' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  padding: 0 !important;
  background-position: calc(100% - 0.55rem) center !important;
  -moz-appearance: none !important;
  appearance: none !important;
  select {
    width: 100% !important;
    font-size: inherit !important;
    font-family: inherit !important;
    color: inherit !important;
    padding: 0 1rem 0 0 !important;
    overflow: hidden !important;
    text-overflow: ellipsis;

    option {
      color: $black;
    }
  }
  &::after {
    display: none !important;
  }
}
