@import '../../../styles/colors';
.Seasons {
  .status {
    width: 200px;
    text-align: center;
  }
  .episodes {
    width: 200px;
    text-align: center;
  }
  [class^='Table'] {
    min-height: 100% !important;

    table thead tr {
      background: $grey95;
    }
    table th,
    table td {
      margin: 0;
      padding: 0.85rem 0 0.85rem 1rem;
    }
  }
  &-confirmation-modal {
    align-items: center;
    margin: auto;
  }
}
