@import '../../styles/base.scss';

.Activity-log {
  .filters-header {
    text-transform: uppercase;
    padding: 0 0.1875rem 0.5625rem;
    font-size: 0.75rem;
    color: $grey40;
  }
  .search-input::placeholder {
    opacity: 0.3;
  }
  .activity-type {
    padding-left: 2.6875rem;
  }
  .status {
    text-align: center;
  }
  .date {
    width: 230px;
  }
  &-no-results {
    text-align: center;
  }
}
