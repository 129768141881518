@import '../../styles/base.scss';
.Tag-list {
  padding-top: 0rem;
  padding-bottom: 0.25rem;
  > div {
    margin-bottom: 0 !important;
    margin-top: 0.25rem;
    color: $black !important;
    background-color: $primary-color !important;
    border: none;
    border-radius: 3px;
    [class^='Tag-remove'] {
      color: $black !important;
      font-size: 0.9rem;
    }
  }
  input {
    margin-bottom: 0 !important;
    margin-top: 0.25rem;
  }
}
