@import '../../styles/base.scss';

.Collections {
  .type {
    width: 100px;
  }
  .date {
    width: 200px;
  }
}
