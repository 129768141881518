@import '../../styles/base.scss';

.Edit-panel {
  &.Panel {
    padding: 0;
    border-radius: $border-radius;
    border: none;
    .Button-disabled {
      background-color: rgba($white, 0.6) !important;
      color: $black !important;
    }
    > div {
      border-radius: 0;
    }
    [class^='Panel-titleBar'] {
      margin: 0;
      border-radius: $border-radius $border-radius 0 0;
      h2 {
        margin-left: 0;
      }
    }
  }
  &-content {
    border-radius: 0 0 $border-radius $border-radius !important;
  }
}
