@import '../../styles/base.scss';

.My-profile {
  margin-left: 1rem;
  margin-top: 0;
  margin-right: 2.5rem;
  &-title {
    color: $dark-grey;
    line-height: 0;
  }
  &-content {
    color: $black;
  }
  &-bg {
    background: #fff;
    border: 1px solid #e5e6e6;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: $border-radius;
  }
}
