$black: #000000;
$white: #ffffff;
$primary-color: #38ba74;
$cyan: #00ddf4;
$grey20: hsla(0, 0%, 0%, 0.2);
$grey25: rgba(19, 19, 19, 0.6);
$grey30: #212121;
$grey35: #3a3a3a;
$grey40: #444444;
$grey50: #808080;
$grey60: rgba(217, 217, 217, 0.68);
$grey70: rgba(255, 255, 255, 0.7);
$grey75: #bebebe;
$gray80: #cccccc;
$grey85: #d7d7d7;
$grey87: #dbdbdb;
$grey90: #dddddd;
$grey95: #ececec;
$grey97: #f0efef;
$grey98: #f6f6f6;
$dark-grey: hsla(0, 10%, 20%, 0.6);
$red: #ff3f3f;
$burgundy: #ac0a0a;
$concrete: #f3f3f3;
$cod-gray: #181818;

$primary-color: $cyan;
$primary-color-dark: darken($cyan, 5%);
