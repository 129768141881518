@import '../../styles/base';

.ErrorScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  border: 4px;
  height: 356px !important;

  svg {
    width: 29px;
    fill: $grey50 !important;
    height: 25px;
  }

  .title {
    width: 350px;
    font-family: $font-family-default;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 1rem;
  }
}
