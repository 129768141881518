@import '../../../styles/base.scss';
.Locale-information-panel {
  .customize-btn {
    display: none;
    font-weight: bold;
  }
  .disabled-customize-btn {
    padding: 0.55rem 0;
    text-decoration: underline;
    font-weight: bold;
    color: $dark-grey;
  }
  .isEditing {
    .disabled-customize-btn {
      display: none;
    }
    .customize-btn {
      display: inline-block;
    }
  }
}
