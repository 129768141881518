@import '../../styles/base.scss';

.Collections-create {
  .label {
    color: $grey40;
    opacity: 0.7;
  }

  .collection-name-wrapper {
    width: 100%;
    max-width: 503px;
  }

  .Panel {
    .Button-disabled {
      background-color: rgba($white, 0.6) !important;
      color: $black !important;
    }
    .SimpleSelect,
    .TextInput {
      border: 0.5px solid rgba($black, 0.2) !important;
    }
    .TextInput {
      width: 100%;
    }
  }
}
