@import '../../styles/base.scss';

.Create-page {
  .label {
    color: $grey40;
    opacity: 0.7;
  }
  .page-name-wrapper {
    width: 100%;
    max-width: 22.125rem;
  }
  .Panel {
    .Button-disabled {
      background-color: rgba($white, 0.6) !important;
      color: $black !important;
    }
    .SimpleSelect,
    .TextInput {
      border: 0.03125rem solid rgba($black, 0.2) !important;
    }
    .TextInput {
      width: 100%;
    }
  }
}
