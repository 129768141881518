@import '../../../../styles/base.scss';

.Section-Content {
  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.375rem 1rem;
    &.isHeroSection {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .date {
    padding-right: 1rem;
    width: fit-content;
  }
  .customize-btn {
    display: none;
    font-weight: bold;
  }
  .disabled-customize-btn {
    padding: 0.55rem 0;
    text-decoration: underline;
    font-weight: bold;
    color: $dark-grey;
  }
  .isEditing {
    .disabled-customize-btn {
      display: none;
    }
    .customize-btn {
      display: inline-block;
    }
  }
}
