@import '../../styles/base.scss';
.Counter {
  &-input {
    width: 36px;
    height: 36px;
    text-align: center;
    background-color: $white;
    border: 0.5px solid $grey20;
    border-radius: 4px;
  }
  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17px;
    width: 17px;
    background: $primary-color;
    text-align: center;
    border-radius: 50%;
    color: $white;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
}
