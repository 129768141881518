@import '../../../styles/base.scss';

$field-border: 0.5px solid $grey20;

.Cta-modal {
  max-height: 80vh !important;
  max-width: 807px !important;

  & [class^='Modal-actions'] {
    padding-bottom: 1.875rem !important;
  }

  [class^='Modal-body'] {
    overflow-y: auto;
  }

  .display-type {
    padding-bottom: 0.4375rem;
    border-bottom: 2px solid transparent;
    font-size: 0.9375rem;
    &:hover {
      cursor: pointer;
    }
    &.selected {
      font-weight: bold;
      border-bottom-color: $primary-color;
      color: $primary-color;
    }
  }

  .grid-display {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1.5rem;
  }

  .advanced-display {
    .json-content {
      line-height: 1.5rem;
    }
  }

  .label {
    color: $grey40;
    opacity: 0.7;
  }

  .TextInput {
    width: 100%;
    border-radius: 4px;
    border: $field-border;
  }

  .SimpleSelect {
    width: 100%;
    border-radius: 4px;
    border: $field-border !important;
  }

  .Text-area {
    height: 300px;
  }

  .duration {
    border: $field-border;
    border-radius: 4px;
    padding-right: 0.75rem;
    .TextInput {
      border: none;
    }
    > div {
      color: $grey40;
      opacity: 0.7;
    }
  }

  .placement {
    &-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      height: 90px;
    }
    &-option {
      display: flex;
      background-color: $grey75;
      border-radius: 2px;
      padding: 0.375rem;
      > div {
        height: 25px;
        width: 45px;
        border-radius: 2px;
        background-color: $black;
      }
      &.selected {
        background-color: $primary-color;
      }
    }
  }

  .full-width-grid-item {
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  .delete-btn {
    cursor: pointer;
  }

  .answers-table {
    thead {
      display: none;
    }
    .answer {
      padding: 0.375rem 0.75rem 0.375rem 0;
      input {
        border-right: $field-border;
      }
    }
    .deletion,
    .dragging {
      width: 20px;
      text-align: center;
      padding: 0 0.5rem 1rem;
      vertical-align: bottom;
    }
    .correct-answer-checkbox {
      margin-left: auto;
      input:before {
        width: 1rem;
        height: 1rem;
        margin-top: 3px;
      }
    }
  }

  .rw-widget.rw-open .rw-popup-container.rw-calendar-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .json-error-container {
    .json-content {
      border-color: $red !important;
    }
  }

  .json-error-message {
    color: $red;
  }
}
