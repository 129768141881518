@import '../../styles/base.scss';

.Sub-header {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  height: 89px !important; /* fix .app-body > div {height: 100%;} */
  color: $white;
  background-color: $grey40;
  padding: 0.5rem 0.5625rem 0 0.9375rem;
  &-info {
    max-width: 65%;
    &-wrapper {
      display: flex;
      align-items: flex-end;
    }
    &-icon {
      svg {
        width: 28px;
        height: 28px;
      }
    }
    &-subtitle {
      font-size: 10px;
      color: $grey70;
    }
    &-title {
      font-size: 27px;
      line-height: 27px;
    }
    &-quantity {
      font-size: 14px;
      line-height: 24px;
    }

    &-detail {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      width: 100%;
      &-title {
        font-size: 27px;
        width: 100%;
        max-height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &-subtitle {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 11px;
        color: $grey70;
      }
    }
  }
  &-actions {
    padding-right: 2rem;
  }
  &-info,
  &-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
