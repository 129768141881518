@import '../../styles/base.scss';

.User-management {
  .content {
    width: calc(100% - $sidebar-width);
    display: flex;
    flex-direction: column;
  }
  .status {
    min-width: 80px;
  }
  .lastLogin {
    min-width: 180px;
  }
}
