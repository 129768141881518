@import '../../styles/base.scss';

.AccessControls {
  &-column-update-item {
    &-title {
      color: $black;
      text-decoration: none;
    }
    &-description {
      color: $dark-grey;
    }
  }

  &-table {
    table {
      th,
      td {
        margin: 0;
        padding: 0.7rem 0;
      }

      th:first-child,
      td:first-child {
        padding-left: 2.5rem;
        width: 5rem;
      }
    }
  }
}

.AccessControlsDetail {
  &-title {
    color: $dark-grey;
    line-height: 0;
  }
}
[class^='Toaster-flashQueue'] {
  height: unset !important;
}
