@import '../../styles/base.scss';
$font-size-card-title: 15px;
$font-size-card-body: 14px;
.Delete-confirmation-modal {
  width: 484px;
  margin: auto;
  &-header {
    background-color: $black;
    height: 58px;
    padding: 0 0 0 2.5rem;
    display: flex;
    align-items: center;
    border-radius: $border-radius $border-radius 0 0;
  }
  &-header > span {
    display: none;
  }
  &-title {
    font-size: $font-size-card-title;
    color: $white;
    background-color: $black;
  }
}
// body
.Delete-confirmation-modal > div:nth-child(2) {
  padding-left: 2.6rem;
}
// buttons
.Delete-confirmation-modal > div:last-child {
  justify-content: center;
  border-radius: 0 0 $border-radius $border-radius;
  padding-top: 2.1rem;
  padding-bottom: 3.1rem;
}
