@import '../../../styles/base.scss';

.Page-detail-sections {
  .Table {
    tr {
      @include flexBox($display: flex, $alignItems: center);
      position: relative;
    }
    td,
    th {
      padding-left: 0;
      &.status {
        text-align: center;
      }
    }
    thead {
      background-color: $grey97;
      th {
        padding: 0.75rem 0;
        &:first-child {
          padding-left: 1.5rem;
        }
        &:last-child {
          padding-right: 1.5rem;
        }
      }
    }
    tbody tr {
      td {
        padding-bottom: 1rem;
        &:first-child {
          padding-left: 1.5rem;
        }
        &:last-child {
          padding-right: 1.5rem;
        }
      }
    }
    .deletion,
    .dragging {
      position: absolute;
      display: none;
    }
    .dragging {
      right: 0.5rem;
    }
    .deletion {
      right: 2.5rem;
    }
    &.editing {
      .deletion,
      .dragging {
        display: table-cell;
      }
    }
  }
}
