@import '../../styles/base.scss';
.Events-create {
  .Panel {
    .Button-disabled {
      background-color: rgba($white, 0.6) !important;
      color: $black !important;
    }
    border-radius: 4px;
    > div {
      border-radius: 4px 4px 0 0;
    }
  }
  .TextInput {
    border-radius: 4px;
  }
}
