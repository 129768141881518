@import '../../styles/base.scss';

.Add-page-modal {
  max-width: 80rem;
  max-height: unset;
  &-header {
    height: 3.75rem;
    align-items: center;
    background-color: $black;
    border-radius: $border-radius $border-radius 0 0;
  }
  &-title {
    font-size: 0.9375rem;
    color: $white;
    text-transform: capitalize;
  }
  .close-btn {
    width: auto;
    height: 1.5625rem;
  }
  & [class^='Modal-close'] {
    font-size: 1.5rem;
    color: $white;
  }
  & [class^='Modal-body'] {
    width: 100%;

    .Pages-content {
      width: 100%;
    }

    .Sub-header {
      padding-top: 0;
      padding-left: 1.625rem;
      &-info {
        display: none;
      }
    }
  }
  & [class^='Modal-actions'] {
    @include flexBox($display: flex, $alignItems: center, $justifyContent: center);
    height: 5.9375rem;
    background-color: $grey40;
  }
  table {
    tbody {
      display: block;
      table-layout: fixed;
      height: 33vh;
      background-color: $grey98;
      overflow-y: auto;
      overflow-x: hidden;
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      margin: 0 !important;
    }
  }
}
