@import '../../styles/base.scss';
.Radio-group {
  display: flex;
  & div {
    display: flex;
    [class^='RadioInput-radioInput'] {
      margin-top: 0 !important;
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
  [class^='RadioInput-bullet'],
  [class^='RadioInput-selected'] {
    background-color: $primary-color !important;
  }
}
