@import '../../styles/base.scss';
.Add-item-modal {
  max-height: unset;
  max-width: 1280px !important;
  &-header {
    height: 59px;
    background-color: $black;
    align-items: center;
    border-radius: $border-radius $border-radius 0 0;
  }
  &-title {
    color: $white;
    font-size: 0.9375rem;
    text-transform: capitalize;
  }
  .close-btn {
    height: 25px;
    width: auto;
  }
  & [class^='Modal-close'] {
    font-size: 1.5rem;
    color: $white;
  }
  & [class^='Modal-body'] {
    width: 100%;
  }
  & [class^='Modal-actions'] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95px;
    background-color: $grey40;
  }
  &-content {
    .sub-header {
      height: 85px;
      background-color: $grey40;
      display: flex;
      align-items: center;
      padding-left: 1.625rem;
    }
  }
  .Side-navigation-item {
    background-color: rgba($white, 0.2);
    &-selected {
      background-color: $grey40;
    }
  }
  tbody {
    height: 45vh;
    table-layout: fixed;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $grey98;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
