@import '../../../styles/base.scss';

.LocaleInformation {
  .name,
  .short-description {
    width: 47.4rem;
    input,
    textarea {
      font-family: $font-family-default;
      font-size: 0.875rem;
      width: 100%;
    }
  }

  .cta-info {
    .title {
      min-width: 80px;
    }
  }
}
