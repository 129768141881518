@import '../../../styles/base.scss';

.Site-configuration-menu {
  .Table {
    tr {
      @include flexBox($display: flex, $alignItems: center);
      position: relative;
    }
    td,
    th {
      padding-left: 0;
      &.status {
        text-align: center;
      }
    }
    thead th {
      background-color: $grey97;
      padding: 0.75rem 0;
      &:first-child {
        padding-left: 2.25rem;
      }
      &:last-child {
        padding-right: 1.5rem;
      }
    }
    tbody tr {
      &:first-child {
        padding-top: 0.5rem;
      }
      td {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        &:first-child {
          padding-left: 2.25rem;
        }
        &:last-child {
          padding-right: 1.5rem;
        }
      }
    }
    .change-page-button {
      display: none;
      color: $primary-color !important;
      background-color: transparent !important;
      height: auto !important;
      padding: 0 !important;
      margin: 0 !important;
      text-decoration: underline !important;
    }
    .dragging {
      position: absolute;
      right: 0.5rem;
    }
    .deletion {
      position: absolute;
      right: 2.5rem;
    }
    .deletion,
    .dragging,
    .change-page {
      display: none;
    }
    &.isEditing {
      .deletion,
      .dragging,
      .change-page-button {
        display: table-cell;
      }
    }
  }
  .Button.add-menu-item-button {
    margin-left: 2.25rem !important;
  }
}
