@import '../../styles/base.scss';
$font-size-button: 14px;

.Button {
  border-radius: $border-radius !important;
  height: 34px !important;
  padding: 0 1.1rem !important;
  border: none !important;
  font-weight: normal !important;
  line-height: normal !important;
  font-size: $font-size-button !important;
  font-family: $font-family-default !important;

  &-danger {
    background-color: $black !important;
    color: $white !important;
  }
  &-primary {
    background-color: $primary-color !important;
    color: $white !important;
  }
  &-secondary {
    background-color: $white !important;
    color: $black !important;
    border: 1px solid $black !important;
  }
  &-secondary-invert {
    background-color: transparent !important;
    color: $white !important;
    border: 1px solid $white !important;
  }
  &.Button-disabled {
    // bumps up specificity to override default color
    &[type='button'] {
      background-color: $grey20 !important;
      color: $white !important;
      opacity: 0.7;
    }
  }
  &-link {
    background: none !important;
    color: $primary-color-dark !important;
    border: 0 !important;
    padding: 0 !important;
    span {
      text-decoration: underline;
    }
  }
  &-link-disabled {
    background: none !important;
    color: $grey20 !important;
    cursor: none;
    border: 0 !important;
    padding: 0 !important;
    span {
      text-decoration: underline;
    }
  }
}
