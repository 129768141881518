@import '../../styles/base.scss';

.Radio-group-date-picker {
  [class^='RadioInput-bullet'],
  [class^='RadioInput-selected'] {
    background-color: $primary-color !important;
  }
  .invalid-time {
    border: 1px solid $red;
  }
}
.Radio-group-date-picker-schedule {
  & [class^='RadioInput-label'] > div {
    display: none;
  }
  & [class^='RadioGroup-groupContents'] {
    padding-left: 0;
  }
  display: flex;
  align-items: center;
}
