@import '../../styles/base.scss';

.Interaction-summary-modal {
  width: 100%;
  max-width: 656px;
  margin: 0 auto;
  [class^='Modal-body'] {
    width: 100%;
  }
  .title {
    font-size: 0.9375rem;
    color: $black;
    line-height: normal;
  }
  .action {
    margin: 0 auto;
    display: block;
  }
  .Panel {
    border: none;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .Table {
    table {
      table-layout: fixed;
      width: 100%;
      th,
      td {
        padding-left: 3.25rem;
      }
      th:first-child,
      td:first-child {
        padding-left: 1.6875rem;
      }
      thead {
        background-color: $grey97;
        th {
          padding-top: 0.8125rem;
          padding-bottom: 0.6875rem;
        }
      }
      tbody {
        tr {
          > td:first-child {
            background-color: $grey87;
            padding-top: 1.5625rem;
            padding-bottom: 1.5625rem;
          }
          &:last-child {
            .tooltip {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .category {
    .tooltip {
      display: none;
    }
    &.survey, &.quiz {
      text-transform: capitalize;
    }
  }
}

.tooltip {
  &.light {
    [class^='ToolTipIcon-wrapper'] {
      background-color: $white;
    }
  }
  &.dark {
    [class^='ToolTipIcon-wrapper'] {
      background-color: $grey85;
    }
  }
  [class^='ToolTipIcon-wrapper'] {
    color: rgba($black, 0.6) !important;
    height: 11px;
    width: 11px;
    vertical-align: unset;
    &::after {
      content: 'i';
      font-size: 9px;
      font-weight: bold;
      line-height: 1px;
      vertical-align: middle;
    }
    svg {
      display: none;
    }
  }
  &-box {
    border-radius: 4px;
    border: 1px solid $black !important;
    color: $grey35 !important;
    padding: 0.375rem 0.5rem;
    [class^='ToolTip-arrow'] {
      border-right: 1px solid $black;
      border-bottom: 1px solid $black;
      box-shadow: none;
      bottom: -5.5px !important;
    }
  }
}
