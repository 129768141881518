@import '../../styles/base.scss';

.Images {
  img {
    width: inherit;
  }

  .container-poster {
    width: 265px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .container-thumbnail {
    width: 230px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .image-upload {
    cursor: pointer;
    background: $grey95;
    padding: 0;

    &-thumbnail {
      width: 121px;
    }

    [class^='InputGroup-labelLeft'],
    [class^='InputGroup-labelWrap'] {
      display: none;
    }
  }

  [class^='Thumbnail-image'] {
    object-fit: contain;
  }

  [class^='ToolTip-wrapper'] {
    svg {
      color: $primary-color;
    }

    &:last-child {
      display: none !important;
    }
  }
}
