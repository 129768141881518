@import '../../styles/base.scss';

.Collections-detail {
  .Table {
    thead {
      background-color: $grey97;
    }
  }
  &-thumbnail {
    width: 78px !important;
    height: 44px !important;
    img {
      border-radius: $border-radius !important;
    }
  }
  .date {
    width: 200px;
  }
  .type {
    width: 140px;
  }
  .deletion,
  .dragging {
    display: none;
  }
  .isEditing {
    .deletion,
    .dragging {
      display: table-cell;
    }
  }
  .delete-rule-btn {
    color: $red !important;
  }
  .Radio-group {
    > div {
      display: block;
      [class^='RadioInput'] {
        margin-left: 0 !important;
        margin-top: 0.25rem;
      }
    }
  }
  .Tag-list {
    width: 250px;
  }
  .SimpleSelect {
    border: 0.5px solid $grey20 !important;
  }
  hr {
    border-width: 0.5px;
    border-color: $grey20;
  }
}
