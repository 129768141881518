@import '../../styles/base.scss';
.Page-section {
  [class^='Panel-titleBar'] {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .page-content {
    width: calc(100% - 281px);
    padding: 3rem;
  }
  .side-menu {
    padding: 3rem 3rem 0 0;
  }
  &.static-page {
    [class^='Panel-titleBar'] {
      .Button {
        display: none;
      }
    }
  }
  .panel-label,
  .panel-text {
    p {
      margin: 0;
    }
  }
}
