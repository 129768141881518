@import '../../styles/base.scss';

.Panel {
  font-family: $font-family-default !important;
  width: 100%;
  height: fit-content;
  border: none !important;

  // bg title
  & [class^='Panel-titleBar'] {
    height: 60px !important;
    background-color: $black !important;
  }
  //title
  & > div h2 {
    font-family: $font-family-default !important;
    color: $white !important;
    font-size: 15px !important;
    margin-left: 1rem;
  }
  .Button-disabled[type='button'] {
    background-color: rgba(255, 255, 255, 0.6) !important;
    color: #000000 !important;
  }
  .body {
    padding: 0.875rem 1rem;
  }
  .title {
    color: $dark-grey;
    line-height: 0;
  }
  .subtitle {
    color: $dark-grey;
    line-height: 0;
    font-size: 12px;
  }
  .content {
    color: $black;
  }
  .link {
    color: $primary-color-dark !important;
    text-decoration: underline;
    :visited,
    :link {
      color: $primary-color-dark;
    }
  }
  .panel-label {
    color: $dark-grey;
    font-size: 0.75rem;
  }
}

.Panel,
.Panel > div {
  border-radius: $border-radius $border-radius 0 0;
}
