@import '../../styles/base.scss';

.Page-detail {
  .panel {
    &-group {
      width: 100%;
      margin-bottom: 1rem;
    }
    &-label {
      font-size: 0.75rem;
      margin: 0;
    }
    &-text,
    &-text-input {
      margin-top: 0.25rem;
    }
    &-text-area-input {
      @include font($fontFamily: $font-family-default !important, $size: 0.8125rem !important, $weight: 400);
      max-width: 100%;
      width: 100%;
      height: 8.375rem;
      padding: 1rem !important;
      border-radius: 0.25rem;
      border: 0.03125rem solid rgba($black, 0.2);
      margin-top: 0.35rem !important;
      background-color: $concrete !important;
      &.editing {
        background-color: $white !important;
      }
    }
  }
  &-information {
    max-width: 45rem;
  }
  &-locale-information {
    .panel-text-area-input {
      max-width: 45rem;
    }
    &-cta {
      max-width: 50rem;
    }
  }
  .Images {
    .Panel {
      padding: 0 0 1rem;
      [class^='Panel-titleBar'] {
        margin: 0 0 0.75rem;
      }
      h2 {
        margin: 0;
      }
      .title {
        font-size: 0.75rem;
      }
      .Button-disabled {
        background-color: rgba($white, 0.6) !important;
        color: $black !important;
      }
    }
  }
}
