@import '../../styles/base.scss';
.Events-detail {
  .page-content {
    width: calc(100% - 281px); // accounts for width of side menu
    padding: 3rem;
  }
  [class^='Panel-titleBar'] {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    h2 {
      margin: 0;
    }
  }
  .empty-panel {
    padding-bottom: 0;
    margin-bottom: 1.875rem;
    [class^='Panel-titleBar'] {
      margin-bottom: 0;
      border-radius: $border-radius;
    }
  }
  .Edit-panel {
    margin-bottom: 1.875rem !important;
    thead {
      background-color: $grey97;
    }
  }
  &-page {
    .Add-item-modal {
      max-width: 984px !important;
    }
  }
  .Availability,
  .Images {
    .Panel {
      padding: 0 0 1rem;
      [class^='Panel-titleBar'] {
        margin: 0 0 0.75rem;
      }
      .Button-disabled {
        background-color: rgba($white, 0.6) !important;
        color: $black !important;
      }
    }
  }
  .side-menu {
    padding: 3rem 3rem 0 0;
  }
  #trailer,
  #final-video {
    .video-js .vjs-big-play-button {
      font-size: 1.5rem;
    }
  }
  .event-player-wrapper .ReactPlayer {
    &-container {
      .bc-player-wrapper {
        max-width: $desktop-small;
        width: 100%;
        aspect-ratio: auto !important;
      }
    }
  }
  .Table .date-column {
    width: 270px;
  }
}
