@import '../../styles/base.scss';

.Locations-detail {
  .label {
    color: $grey40;
    opacity: 0.7;
  }
  .input-field {
    max-width: 596px;
    width: 100%;
    height: 36px;
  }
  .countries-input {
    > [role='combobox'] {
      > div {
        border-radius: 4px;
        > div {
          background-color: $primary-color !important;
          color: $black !important;
          font-size: 0.875rem;
          border: none !important;
          border-radius: 3px !important;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }
        [aria-label='remove'] {
          color: $black;
        }
      }
    }
  }
  .Panel {
    .Button-disabled {
      background-color: rgba($white, 0.6) !important;
      color: $black !important;
    }
  }
}
