@import './_colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

// Font
$font-family-default: 'Open Sans', sans-serif;

$header-height: 5.3rem;
$desktop-max-width: 1280px;
$desktop-medium: 1440px;
$desktop-small: 1280px;
$tablet: 1024px;
$tablet-potrait: 768px;
$mobile: 640px;
$border-radius: 6px;
$sidebar-width: 240px;

@mixin font(
  $fontFamily: false,
  $size: false,
  $color: false,
  $weight: false,
  $style: false,
  $lh: false,
  $ls: false
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $style {
    font-style: $style;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}

@mixin flexBox(
  $display: false,
  $flexDirection: false,
  $flexFlow: false,
  $alignItems: false,
  $justifyContent: false,
  $flexWrap: false,
  $flex: false
) {
  @if $display {
    display: $display;
  } @else {
    display: flex;
  }
  @if $flexDirection {
    flex-direction: $flexDirection;
  }
  @if $flexWrap {
    flex-wrap: $flexWrap;
  }
  @if $flexFlow {
    flex-flow: $flexFlow;
  }
  @if $alignItems {
    align-items: $alignItems;
  }
  @if $justifyContent {
    justify-content: $justifyContent;
  }
  @if $flex {
    flex: $flex;
  }
}

@mixin equal-dimensions($value) {
  height: $value;
  width: $value;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body,
#root {
  border: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: auto;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-size: 14px;
  margin: 0;
  font-family: $font-family-default;
}

.app-layout {
  position: relative;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  overflow: auto;
  background-color: $grey98;
}

.app-body {
  position: relative;
  // max-width: $desktop-max-width;
  min-height: calc(100vh - 5.3rem);
  margin: 5.3rem auto 0;

  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  > div {
    height: 100%;
    flex: 1;
  }

  > [class^='Toaster-'] {
    height: auto;
  }

  .main-content {
    flex: 1;
    min-height: 100vh;
    margin: 0 auto;
    padding: 3.125rem 2.6875rem;
  }

  div,
  p {
    word-break: break-word;
  }
}

// required to enable position:sticky on pages with sticky side menu
.disabled-overflow {
  .app {
    &-layout,
    &-body {
      overflow: initial;
    }
  }
}

.visibility-hidden {
  visibility: hidden !important;
}

.hidden {
  display: none !important;
}

.divider-line {
  width: 100%;
  height: 6px;
  background-color: $grey95;
}

.select-input-lg {
  width: 233px;
  height: 36px !important;
  border-radius: 4px !important;
  border: none !important;
  font-size: 0.875rem !important;
  padding-left: 0.625rem !important;
}

.select-input-sm {
  width: 103px;
  height: 36px !important;
  border-radius: 6px !important;
  border: 1px solid $gray80 !important;
  font-size: 0.9375rem !important;
  padding-left: 0.75rem !important;
  color: $grey40 !important;
}

.language-dropdown {
  &.alt-theme {
    color: $white !important;
    background-color: rgba($white, 0.3);
    background-image: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
  }
}

.side-menu {
  position: sticky;
  top: 85px; // height of page header
  align-self: flex-start;
}

.required {
  &::after {
    content: ' *';
  }
}

.filter-label {
  font-size: 0.6875rem;
  opacity: 0.5;
  color: $grey40;
}

.spinner-small {
  fill: $grey30;
  width: 15px;
  height: 15px;
}

.spinner-medium {
  fill: $grey30;
  width: 30px;
  height: 30px;
}

.spinner-large {
  fill: $grey30;
  width: 45px;
  height: 45px;
}

.flex-no-grow {
  flex: 0 !important;
}

[class^='Modal-overlay'] {
  background-color: $grey25;
}

.rw-widget-picker {
  border: 0.5px solid $grey20 !important;
  box-shadow: none !important;
}

.rw-picker-btn {
  color: $primary-color;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@mixin width-classes {
  @for $i from 1 through 4 {
    $width: $i * 25;
    .w-#{$width} {
      width: #{$width}#{'%'};
    }
  }
}

@include width-classes;
$sizes: 12;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m-#{$i} {
      margin: $margin !important;
    }
    .ml-#{$i} {
      margin-left: $margin !important;
    }
    .mr-#{$i} {
      margin-right: $margin !important;
    }
    .mt-#{$i} {
      margin-top: $margin !important;
    }
    .mb-#{$i} {
      margin-bottom: $margin !important;
    }
    .mx-#{$i} {
      margin-left: $margin !important;
      margin-right: $margin !important;
    }
    .my-#{$i} {
      margin-top: $margin !important;
      margin-bottom: $margin !important;
    }
  }
}

@include margin-classes;

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p-#{$i} {
      padding: $padding !important;
    }
    .pl-#{$i} {
      padding-left: $padding !important;
    }
    .pr-#{$i} {
      padding-right: $padding !important;
    }
    .pt-#{$i} {
      padding-top: $padding !important;
    }
    .pb-#{$i} {
      padding-bottom: $padding !important;
    }
    .px-#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
    .py-#{$i} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}

@include padding-classes;

@mixin all-heights($value) {
  min-height: $value;
  height: $value;
  max-height: $value;
}
