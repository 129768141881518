@import '../../styles/colors';
.CuePointsBar {
  width: 920px;
  margin: auto;
  &-container {
    position: relative;
  }
  .progressBar {
    position: relative;
    height: 5px;
    background-color: $grey75;
    cursor: pointer;
    border-radius: 1rem;
  }
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $primary-color;
    border-radius: 1rem;
  }
  &-cuepoint-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: -8px;
    width: 10px;
  }
  .diamond {
    width: 10px;
    height: 10px;
    background: $grey40;
    transform: rotate(45deg);
    cursor: pointer;
  }
  .diamond:hover::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba(255, 251, 251, 0.3);

    z-index: -10;
    animation: diamond 0.2s ease forwards;
  }
  @keyframes diamond {
    0% {
      background-color: rgba(255, 251, 251, 0);
    }
    100% {
      background-color: rgba(255, 251, 251, 0.3);
    }
  }
  .time-indicator {
    display: flex;
    justify-content: space-between;
  }
}
