@import '../../styles/base.scss';
.Standard-modal {
  max-height: unset;
  .Generic-modal-create {
    &-header {
      height: 59px;
      background-color: $black;
      align-items: center;
      border-radius: 4px 4px 0 0;
      padding-left: 2.5625rem;
    }
    &-title {
      color: $white;
      font-size: 0.9375rem;
      text-transform: capitalize;
    }
  }
  & [class^='Modal-close'] {
    display: none;
  }
  & [class^='Modal-body'] {
    width: 100%;
    background-color: $white;
    padding: 1rem 2.5625rem;
  }
  & [class^='Modal-actions'] {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 0 0 4px 4px;
    padding: 1rem;
  }
}
