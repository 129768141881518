@import '../../styles/base.scss';

.Content-management {
  &-container {
    .Content-management-content {
      width: calc(100% - $sidebar-width);
    }

    .Table {
      overflow: auto;
    }
  }

  &-thumbnail {
    width: 78px !important;
    height: 44px !important;
    img {
      border-radius: $border-radius !important;
    }
  }
  &-published {
    width: 140px;
    text-align: center;
    padding-right: 1rem;
  }
  &-reference-id {
    min-width: 100px;
    padding-right: 2rem !important;
  }
  &-type {
    min-width: 70px;
  }
  &-created,
  &-updated {
    min-width: 150px;
  }
  &-column-updated-item {
    &-title {
      color: $black;
      text-decoration: none;
    }
    &-description {
      color: $dark-grey;
    }
  }
  &-title {
    color: $dark-grey;
    line-height: 0;
  }
}
