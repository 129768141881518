@import '../../../styles/base.scss';

.Content {
  .AutoCompleteTags {
    [class^='TagListInput-container'],
    [class^='Tag-tag'] {
      border-radius: $border-radius;
    }

    [class^='Tag-tag'] {
      background: $primary-color;
      color: $black;
      border: 1px solid $primary-color;

      div:nth-child(2) {
        color: $black;
      }
    }

    [class^='InputGroup-labelLeft'] label {
      font-family: $font-family-default;
      font-weight: normal;
      color: #999 !important;
    }
  }
}
