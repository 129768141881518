@import '../../../styles/base.scss';
.CollectionsContained {
  [class^='Table'] {
    min-height: 100% !important;

    table thead tr {
      background: $grey95;
    }
    table th,
    table td {
      margin: 0;
      padding: 0.85rem 0 0.85rem 1rem;
    }
  }
}
