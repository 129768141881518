@import '../../styles/base.scss';

.Publication-state-buttons {
  .scheduled-date-time {
    margin-right: 1.25rem;
  }
  .unpublished-changes-warning {
    margin-right: 1.25rem;
    span {
      margin-left: 0.5rem;
    }
  }
  .Button.Button-disabled[type='button'] {
    opacity: 0.7;
  }
}

.Delete-confirmation-modal.publish {
  .publish-modal-content {
    p {
      margin: 0 auto 1.5rem;
    }
    .Radio-group-date-picker {
      margin-bottom: 3.5rem;
      > div {
        margin-bottom: 0.75rem;
      }
      .rw-widget.rw-open .rw-popup-container.rw-calendar-popup {
        position: fixed;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -45%);
      }
    }
  }
  > div:last-child {
    padding-bottom: 1.625rem;
  }
}
