@import '../../styles/base.scss';

.Locations {
  display: flex;
  flex-direction: column;
  .selection {
    padding-left: 2.75rem;
    width: 70px;
  }
  .date {
    width: 200px;
  }
}
