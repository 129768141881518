@import '../../styles/base.scss';

.TextInput {
  font-family: $font-family-default;
  padding-left: 0.8rem;
  border: 1px solid $grey20;
  height: 36px;
  border-radius: $border-radius;
  width: 370px;
}
