@import '../../styles/base.scss';
.Checkbox {
  display: flex;
  input {
    &::before {
      content: '';
      background: $white;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid $grey20;
      border-radius: 4px;
      margin-top: -1px;
      margin-left: -1px;
    }
    &:checked:before {
      background-color: $primary-color;
      background-image: url('data:image/svg+xml,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.9613 1.67657L4.64783 9.78903L0 5.21901L1.68142 3.54244L4.36076 6.17899L10.1022 0L11.9613 1.67657Z" fill="white"/></svg>');
      background-repeat: no-repeat;
      background-position: center;
      border: none;
    }
  }
  p {
    margin-left: 0.8rem;
    color: $dark-grey;
    line-height: 0;
  }
}
