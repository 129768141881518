@import '../../styles/base.scss';

.Table {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: auto;
  justify-content: space-between;
  flex-grow: 1;

  .InternalTable {
    width: 100%;
    overflow: auto;
  }

  table {
    font-size: 14px;
    text-align: left;

    border-spacing: 0;
    width: inherit;

    thead {
      background: $white;
      color: $dark-grey;
    }

    tr {
      &.selected {
        td {
          background-color: $grey85;
        }
      }

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tbody {
      color: $black;
    }

    th,
    td {
      margin: 0;
      padding: 0.7rem 0;
      padding-left: 1rem;

      :last-child {
        border-right: 0;
      }
    }

    th {
      position: relative;
      font-weight: normal;
    }
  }

  &-checkbox {
    outline: none;
    content: none;
  }

  &-checkbox::before {
    content: '';
    background: $white;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid $grey20;
    border-radius: 4px;
    margin-top: -1px;
    margin-left: -1px;
  }

  &-checkbox:checked:before {
    background-color: $primary-color;
    background-image: url('data:image/svg+xml,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.9613 1.67657L4.64783 9.78903L0 5.21901L1.68142 3.54244L4.36076 6.17899L10.1022 0L11.9613 1.67657Z" fill="white"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }

  &-custom-radio-button {
    label {
      @include flexBox($display: flex);
      position: relative;
      cursor: pointer;

      input {
        position: absolute;
        left: -9999px;

        &:checked + span {
          &:before {
            box-shadow: inset 0 0 0 0.375rem $primary-color;
          }
        }
      }

      span {
        @include flexBox($display: flex, $alignItems: center);
        transition: 0.25s ease;

        &:before {
          @include flexBox($display: flex);
          content: '';
          width: 1.25rem;
          height: 1.25rem;
          background-color: $white;
          box-shadow: inset 0 0 0 0.0625rem $grey90;
          border-radius: 50%;
          transition: 0.25s ease;
          flex-shrink: 0;
        }
      }
    }
  }

  &-delete-icon {
    * {
      fill: $primary-color-dark;
    }
  }

  .sorting-icon {
    position: absolute;
    right: 0;
    padding-right: 0.5rem;

    svg {
      height: 0.75rem;
      vertical-align: 0;
    }
  }

  .selection {
    width: 40px;
  }

  .subtitle {
    color: $grey40;
    font-size: 0.5625rem;
    opacity: 0.8;
    margin-top: 0.25rem;
  }

  .deletion,
  .dragging {
    width: 40px;
    padding: 0;

    img:hover,
    svg:hover {
      cursor: pointer;
    }
  }

  .published,
  .status {
    width: 120px;
    text-align: center;
    padding-right: 1rem;
  }

  .thumbnail-cell {
    width: 100px;
  }
}

.thead-grey {
  table thead {
    background: $grey97;
  }
}
