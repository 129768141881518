@import '../../styles/base.scss';

.Header {
  display: flex;
  align-items: center;
  padding-left: 2.6875rem;
  padding-right: 1.75rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: $black;
  height: $header-height;

  &-content {
    @include flexBox($alignItems: center);
    width: 100%;
  }

  &-logo {
    width: 145px;
  }

  .Navigation {
    margin-left: 4.0625rem;
  }

  .menu-dropdown {
    padding-left: 3.75rem;
    margin-left: auto;
    &-button {
      font-family: $font-family-default !important;
      font-weight: 300;
      color: $primary-color !important;
    }
  }
}

[class^='Menu-container'] {
  border: 0;
}
[class^='Menu-dropdown'],
[class^='DropdownMenu-menu'] {
  overflow: inherit;
}
