@import '../../styles/base.scss';

.Roles {
  .actions {
    margin-left: auto;
  }
  table {
    border-collapse: collapse;
    border-right: 0.5px solid $grey90;
    border-left: 0.5px solid $grey90;
    width: 100%;
    overflow: auto;
  }
  thead {
    tr {
      height: 52px;
    }
    th {
      padding-top: 0;
      padding-bottom: 0;
    }
    .role,
    .selection {
      background-color: $grey90;
      color: $black;
    }
    .selection {
      line-height: 1.75rem;
    }
    .module {
      padding-left: 2.1rem;
      padding-right: 0.5rem;
    }
  }
  tbody {
    tr {
      height: 64px;

      .module {
        vertical-align: top;
      }
    }
    .role,
    .selection {
      background-color: $white;
    }
    .selection {
      line-height: 2rem;
    }
    .role {
      &:nth-child(2) {
        min-width: 220px;
      }
      &-name-container {
        min-width: 230px;

        &.clickable {
          .role-name {
            text-decoration: underline;
          }

          &:hover {
            border-bottom: 1px solid $grey95;
            cursor: pointer;
          }
        }
      }
      &-name {
        color: $primary-color;
        text-decoration: none;

        &.clickable:hover {
          cursor: pointer;
        }
      }
      .default-marker {
        font-size: 0.625rem;
        background-color: $black;
        color: $white;
        border-radius: 3px;
        padding: 0.1rem 0.3125rem;
        margin: 0 0.6875rem;
        min-width: 18px;
      }
    }
    .table-cell {
      min-width: 100px;
    }
    .module {
      background-color: $grey95;
      span {
        margin-left: 0.1rem;
      }
    }
  }
  td,
  th {
    border-top: 0.5px solid $grey90;
    border-bottom: 0.5px solid $grey90;
  }
  th:last-child {
    padding-right: 2.1rem;
  }
  td:last-child {
    padding-right: 1rem;
  }
  .selection {
    padding: 0 0.25rem;
    width: 39px;
  }
  .role {
    border-right: 0.5px solid $grey90;
    padding-left: 1.54rem;
    padding-right: 1.54rem;
    width: 196px;
  }
  .overflow-auto {
    overflow: auto;
  }
}
