@import '../../styles/base.scss';

.TableOfContents {
  &-container {
    margin: 0 auto;
  }
  &-list {
    background: $white;
    min-width: 225px;
    border-radius: $border-radius;
    padding: 1rem 1rem 0 0;

    &.hasBorder {
      border: 0.5px solid rgba($black, 0.4);
      border-radius: 4px;
    }

    &-item {
      cursor: pointer;
      margin-left: 1rem;
      padding-bottom: 0.75rem;
      &-active {
        color: $primary-color-dark;
        font-weight: bold;
      }
    }
  }
  &-fixed {
    position: fixed;
  }
  &-sticky {
    position: sticky;
    top: 85px; // height of page header
    align-self: flex-start;
  }
}
