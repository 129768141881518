@import '../../styles/base.scss';
.Input-search {
  display: flex;
  align-items: center;
  background-color: $white;
  height: 36px;
  color: $grey20;
  border-radius: $border-radius;
  max-width: 300px;
  min-width: 235px;
  input {
    font-family: $font-family-default;
    border: none;
    outline: none;
    flex: 1;
    margin-left: 0.6rem;
    padding-top: 0.25rem;
  }
  &-icon {
    color: #ccc;
    font-size: 16px;
    margin-right: 0.5rem;
    cursor: pointer;
  }
}
