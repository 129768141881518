@import '../../../styles/colors';
.Episodes {
  .status {
    width: 200px;
    text-align: center;
  }
  .episodes {
    width: 200px;
    text-align: center;
  }
  &-Panel {
    [class^='Panel-titleBar'] {
      margin-bottom: 0 !important;
      margin: 0 !important;
    }

    padding: 0 !important;
  }

  &-Table {
    table thead {
      display: table;
      width: 100%;
    }
    table tbody {
      display: block !important;
      height: 45vh !important;
      overflow-y: scroll !important;
    }

    table thead tr {
      background: $grey95;
    }
    table th,
    table td {
      margin: 0;
      padding: 0.85rem 0 0.85rem 1rem;
    }
  }

  &-Panel-Table {
    table {
      thead tr {
        background: $grey95;
      }

      tbody {
        td {
          &.name {
            min-width: 200px;
          }
          &.extId {
            min-width: 150px;
          }
          &.type {
            min-width: 150px;
          }
          &.date {
            min-width: 160px;
          }
        }
      }
    }
  }

  &-confirmation-modal {
    align-items: center;
    margin: auto;
  }

  &-table-actions {
    background: $grey40;
    padding: 1.5rem;
    border-radius: 0 !important;
  }
}
