@import '../../../styles/base.scss';
.Information {
  .AutoCompleteTags {
    [class^='TagListInput-container'],
    [class^='Tag-tag'] {
      border-radius: $border-radius;
    }
    [class^='Tag-tag'] {
      background: $primary-color;
      color: $black;
      border: 1px solid $primary-color;
      div:nth-child(2) {
        color: $black;
      }
    }
    [class^='InputGroup-labelLeft'] label {
      font-family: $font-family-default;
      font-weight: normal;
      color: #999 !important;
    }
  }

  .info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &-panel-content {
    > div {
      width: 47.4rem;
      input,
      textarea {
        width: 100%;
      }
      .genre {
        width: 20rem;
      }
    }
    textarea {
      font-family: $font-family-default !important;
      font-size: 0.875rem !important;
    }
  }
}
