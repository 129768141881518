@import '../../styles/base.scss';

.Pages {
  .Side-navigation {
    counter-set: side-nav-count -1;
    counter-reset: side-nav-count;
    &-item {
      @include flexBox($display: flex, $alignItems: center);
      counter-increment: side-nav-count;
      &:before {
        @include flexBox($display: flex, $alignItems: center, $justifyContent: center);
        @include font($fontFamily: 'Roboto', $size: 0.9375rem, $lh: 1.125rem, $weight: 700);
        @include equal-dimensions(1.4375rem);
        content: counter(side-nav-count);
        border: 0.09375rem solid $primary-color;
        border-radius: 50%;
        color: $primary-color;
      }
      &:first-child {
        img {
          @include equal-dimensions(1.25rem);
        }
        &:before {
          display: none;
        }
      }
    }
  }
  &-content {
    width: calc(100% - $sidebar-width);
    .create-page-button {
      margin-left: auto !important;
    }
    &-table {
      tr {
        .type,
        .created-date,
        .updated-date {
          width: 13rem;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
