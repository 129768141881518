@import '../../styles/base.scss';

.Add-section-modal {
  max-width: 40rem;
  max-height: unset;
  height: 100%;
  &-header {
    height: 3.75rem;
    align-items: center;
    background-color: $black;
    border-radius: $border-radius $border-radius 0 0;
  }
  &-title {
    font-size: 0.9375rem;
    color: $white;
  }
  .close-btn {
    width: auto;
    height: 1.5625rem;
  }
  &-content {
    .label {
      @include font($size: 0.875rem, $weight: 400, $color: $grey40);
      opacity: 0.7;
      margin: 0.5rem 0;
    }
    .modal-text-input {
      width: 18.75rem;
    }
    .modal-text-input,
    .modal-select-input {
      @include font($size: 0.9375rem, $color: $black);
      border: 0.03125rem solid rgba($black, 0.2) !important;
    }
  }
  & [class^='Modal-close'] {
    font-size: 1.5rem;
    color: $white;
  }
  & [class^='Modal-body'] {
    width: 100%;
    height: 12rem;
    background-color: $white;
    .Sub-header {
      padding-top: 0;
      padding-left: 1.625rem;
      &-info {
        display: none;
      }
    }
  }
  & [class^='Modal-actions'] {
    @include flexBox($display: flex, $alignItems: center, $justifyContent: center);
    height: 5.9375rem;
    background-color: $white;
  }
}
