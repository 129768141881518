@import '../../styles/base.scss';

.Language-dropdown {
  max-width: 14.6875rem;
  width: 100%;
  margin: 0 auto 1.375rem;
  > p {
    margin: 0;
  }
  .title {
    color: $grey40;
    opacity: 0.7;
  }
  .SimpleSelect {
    color: $black;
    border: 0.5px solid $grey20 !important;

    &.disabled {
      background-color: $grey95;
    }
  }
}
