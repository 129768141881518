@import '../../../styles/base.scss';

.Video-panel {
  .rw-date-picker {
    display: none;
  }

  .deletion,
  .dragging {
    display: none;
  }

  .isEditing {
    .rw-date-picker {
      display: block;
    }
    .date {
      display: none;
    }
    .deletion,
    .dragging {
      display: table-cell;
    }
    .InternalTable {
      overflow: visible;
    }
  }

  .date-column {
    width: 250px;
    padding-right: 2rem;
  }

  .video-placeholder {
    min-width: 264px;
    min-height: 165px;
    width: fit-content;
    background-color: $grey60;
  }

  .DateError {
    .rw-widget-picker {
      border-color: $red !important;
    }
    .ErrorMessage {
      color: $red;
      font-style: italic;
      font-size: 12px;
    }
  }
}
