@import '../../styles/base.scss';

.Side-navigation-item {
  font-family: $font-family-default;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $white;
  height: 46px;
  width: $sidebar-width;
  padding-left: 1.5rem;
  cursor: pointer;

  &-selected {
    background-color: $grey40;
  }
  p {
    margin-left: 1.2rem;
    font-size: 12px;
  }
  &-icon {
    svg {
      fill: #505050 !important;
      width: 21px;
      height: 31px;
    }
    &-selected {
      svg {
        fill: $white;
        width: 21px;
        height: 31px;
      }
    }
  }
}
