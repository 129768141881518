@import '../../styles/base.scss';
.Availability {
  .Autocomplete-container {
    width: 32.4rem;
  }
  .AutoCompleteTags {
    [class^='TagListInput-container'],
    [class^='Tag-tag'] {
      border-radius: $border-radius;
    }
    [class^='Tag-tag'] {
      background: $primary-color;
      color: $black;
      border: 1px solid $primary-color;
      div:nth-child(2) {
        color: $black;
      }
    }
    [class^='InputGroup-labelLeft'] label {
      font-family: $font-family-default;
      font-weight: normal;
      color: #999 !important;
    }
    [class^='InputGroup-labelRight'] * {
      stroke: $primary-color !important;
    }
  }
}
