@import '../../styles/base.scss';

$field-border: 1px solid $grey20;

.Customize-modal {
  max-width: 807px;
  max-height: 80vh !important;
  [class^='Modal-body'] {
    overflow-y: auto;
    padding-bottom: 0 !important;
  }
  & [class^='Modal-actions'] {
    padding-top: 2.125rem !important;
    padding-bottom: 2.9375rem !important;
  }
  .label {
    color: $grey40;
    opacity: 0.7;
  }
  .TextInput {
    width: 100%;
    border-radius: 4px;
    border: $field-border;
  }
  .Text-area {
    border: $field-border !important;
    font-family: inherit;
    font-size: 0.875rem;
    padding-left: 0.8rem !important;
  }
  .image-container {
    padding: 1.25rem;
    border: $field-border;
    border-radius: 4px;
  }
  [class^='RadioInput-radioInput'] {
    flex-direction: column;
    margin-left: 0 !important;
    margin-right: 0.5rem;
    &::before {
      content: '';
      height: 85px;
      width: 105px;
      background: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="105" height="85" viewBox="0 0 105 85" fill="none"><path d="M95.5405 0H9.45946C4.16216 0 0 4.14634 0 9.4235V75.5765C0 80.8537 4.16216 85 9.45946 85H95.5405C100.838 85 105 80.8537 105 75.5765V9.4235C105 4.14634 100.838 0 95.5405 0ZM79.2703 15.0779C84.5676 15.0779 88.7297 19.2243 88.7297 24.5014C88.7297 29.7786 84.5676 33.9249 79.2703 33.9249C73.973 33.9249 69.8108 29.7786 69.8108 24.5014C69.8108 19.2243 73.973 15.0779 79.2703 15.0779ZM87.5949 73.1267H15.8922C14.3785 73.1267 13.4324 71.2415 14.3785 70.1109L37.0809 42.9709C37.8371 42.0282 39.1621 42.0282 39.9182 42.9709L56.3773 62.7606C57.1335 63.7032 58.4585 63.7032 59.2146 62.7606L69.6204 50.3212C70.3766 49.3786 71.7015 49.3786 72.4577 50.3212L88.9168 69.9214C90.0544 71.2413 89.2969 73.1265 87.5945 73.1265L87.5949 73.1267Z" fill="black" fill-opacity="0.1"/></svg>')
        no-repeat;
      margin-bottom: 0.5rem;
    }
    [class^='RadioInput-bullet'] {
      bottom: 0.1rem;
    }
  }
}
