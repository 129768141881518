@import '../../styles/base.scss';

.Navigation {
  display: flex;
  &-item {
    text-decoration: none;
    color: $grey70;
    margin: 0 1rem 0 1rem;
    &-selected {
      margin: 0 1rem 0 1rem;
      text-decoration: underline;
      text-decoration-color: $primary-color;
      color: $grey70;
      text-decoration-thickness: 3px;
      text-underline-offset: 8px;
    }
  }
  &-item,
  &-item-selected {
    font-size: 16px;
  }
}
