@import '../../styles/base.scss';

$side-menu-width: 300px;

.Content,
.CollectionsContained,
.Availability,
.Images {
  width: 100%;
}

.ReactVideoPlayer-container {
  background: $grey30;
}
.ReactPlayer-container.standard .ReactPlayer {
  aspect-ratio: 16 / 9;
  width: 800px;
  height: 100%;
  [class^='video-js'] {
    height: 100%;
    width: 100%;
  }
}

.countries-input-field {
  max-width: 596px;
  width: 100%;
  height: 36px;
}

.countries-input {
  > [role='combobox'] {
    > div {
      border-radius: 4px;
      > div {
        background-color: $primary-color !important;
        color: $black !important;
        font-size: 0.875rem;
        border: none !important;
        border-radius: 3px !important;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
      [aria-label='remove'] {
        color: $black;
      }
    }
  }
}

.DetailSeriesSeasons {
  .content-container {
    width: calc(100% - $side-menu-width);
  }
  .side-menu {
    width: $side-menu-width;
  }
}
