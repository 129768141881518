@import '../../styles/base.scss';

.Roles-detail {
  .panel-content {
    padding: 0.125rem 0.8125rem 0.75rem;
  }
  .label {
    color: $dark-grey;
  }
  .checkbox-grid {
    display: grid;
    grid-template-columns: repeat(5, auto);
    max-width: 920px;
    row-gap: 5px;
    margin-top: 0.1rem;
    .Checkbox {
      p {
        color: $black;
      }
    }
  }
  .select-all-btn {
    opacity: 0.6;
  }
  .set-as-default-btn.Button-disabled {
    opacity: 0.7;
  }
}
