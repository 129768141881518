@import '../../styles/base.scss';

.TitleDescription {
  font-family: $font-family-default;
  max-width: 400px;
  &-title {
    color: $primary-color-dark;
    text-decoration: underline;
    margin: 0.3rem 0.5rem 0 0;
  }
  &-description {
    margin: 0.1rem 0.5rem 0 0;
    font-size: 11px;
    color: $dark-grey;
  }
}
