@import '../../../../styles/base.scss';

.Page-detail-featured-content-rules {
  .rule {
    margin-bottom: 1rem;
    &-index {
      @include font($fontFamily: $font-family-default, $size: 0.75rem, $color: $dark-grey);
      margin: 0;
    }
    &-filter {
      @include font($size: 0.875rem, $lh: 1rem, $weight: 700, $color: $primary-color);
      margin: 0;
    }
  }
  &.editing {
    .rule {
      margin-bottom: 0;
      padding-bottom: 1.75rem;
      &-index {
        @include font($size: 0.9375rem, $weight: 500, $color: $cod-gray);
        margin-right: 1.5rem;
      }
      &-delete-btn {
        @include flexBox($display: flex, $alignItems: center);
        span {
          color: $red;
        }
        .minus-icon {
          @include equal-dimensions(0.625rem);
          border-radius: 50%;
          background-color: $red;
          color: $white;
          margin-right: 0.5rem;
        }
      }
      &:after {
        position: relative;
        content: '';
        width: 100%;
        height: 0.375rem;
        margin-top: 1.75rem;
        background-color: rgba($black, 0.05);
      }
      &-content {
        .panel {
          &-label {
            @include font($fontFamily: $font-family-default, $size: 0.875rem, $weight: 400, $color: $grey40);
            opacity: 0.7;
            margin: 0.5rem 0;
          }
          &-rule-item-info {
            @include font($size: 0.6875rem, $lh: 1rem, $weight: 400, $color: $cod-gray);
            list-style-type: none;
            padding: 0;
          }
        }
        .select-input-lg,
        .panel-text-input {
          @include font($size: 0.9375rem, $color: $black);
          width: 21rem;
          border: 0.03125rem solid rgba($black, 0.2) !important;
        }
      }
      &-item {
        width: 30rem;
        &-name {
          font-size: 0.9375rem;
        }
        &-id {
          color: $grey40;
        }
        &-thumbnail {
          width: 11.375rem;
          height: auto;
          background-color: transparent;
          > div {
            border-radius: 0.25rem;
          }
        }
      }
    }
  }
}
